import { render, staticRenderFns } from "./BranchPoint.vue?vue&type=template&id=ff620ec8&scoped=true&"
import script from "./BranchPoint.vue?vue&type=script&lang=js&"
export * from "./BranchPoint.vue?vue&type=script&lang=js&"
import style0 from "./BranchPoint.vue?vue&type=style&index=0&id=ff620ec8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff620ec8",
  null
  
)

export default component.exports
import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'directives', {TouchSwipe});
