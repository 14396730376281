<template>
  <div class="GroupDimension-wrap">
    <div class="myTitle-wrap row items-center justify-between">
      <!-- 標題線 -->
      <hr class="titleLine" />
      <!-- 標題 -->
      <div class="myTitle">群體向度分析</div>
      <!-- 標題線 -->
      <hr class="titleLine" />
    </div>
    <!-- 前言 -->
    <div class="content">
      親愛的XXX
      <br />
      現在共有
      <span>{{ 200 }}</span>
      名乘客，
      <br />
      與你共同踏上升維之旅。
    </div>
    <div class="RadarChartAndBtns">
      <!-- 三角形背景 -->
      <div id="triangle-up"></div>
      <!-- 圖表 -->
      <RadarChart class="RadarChart-component" :chartId="'GroupDimensionRadarChart'" :chartdata="chartdata" :options="options" />
      <!-- 前期後期按鈕 -->
      <div class="btns-wrap">
        <button :class="[showFirstRadarData ? '' : 'noShowData', 'btns firstDataBtn row items-center justify-center']" @click="toggleFirstRadarData">
          <span :class="[showFirstRadarData ? 'bg-checkIcon' : 'bg-unCheckIcon']"></span>
          <span>你自己的</span>
        </button>
        <button :class="[showLastRadarData ? '' : 'noShowData', 'btns lastDataBtn row items-center justify-center']" @click="toggleLastRadarData">
          <span :class="[showLastRadarData ? 'bg-checkIcon' : 'bg-unCheckIcon']"></span>
          <span>旅客平均</span>
        </button>
      </div>
    </div>
    <!-- 網站連結輪播 -->
    <WebLinkCarousel />
  </div>
</template>

<script>
import RadarChart from '../Chart/RadarChart';
import WebLinkCarousel from './WebLinkCarousel';
export default {
  name: 'GroupDimension',
  components: { RadarChart, WebLinkCarousel },
  props: [],
  data() {
    return {
      // 顯示前期資料
      showFirstRadarData: true,
      // 顯示後期資料
      showLastRadarData: true,
      // 圖表資料
      radarData: [
        {
          label: 'RadarChart',
          data: [0.5, 0.5, 0.4],
          backgroundColor: 'rgba(251, 147, 145, 0.6)',
          borderColor: 'rgba(251, 147, 145, 0.6)',
          pointRadius: 0
        },
        {
          label: 'RadarChart1',
          data: [1, 0.4, 0],
          backgroundColor: 'rgba(102, 90, 177, 0.6)',
          borderColor: 'rgba(102, 90, 177, 0.6)',
          pointRadius: 0
        }
      ]
    };
  },
  created() {},
  computed: {
    // 圖表 data
    chartdata() {
      return {
        labels: ['察覺力', '正念力', '突破力'],
        datasets: this.radarData
      };
    },
    // 圖表設定 options
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 1,
            ticks: {
              showLabelBackdrop: false
            },
            angleLines: {
              color: 'rgba(250, 250, 250, 1)'
            },
            pointLabels: {
              color: 'rgba(250, 250, 250, 1)'
            }
          }
        }
      };
    }
  },
  methods: {
    toggleFirstRadarData() {
      this.showFirstRadarData = !this.showFirstRadarData;
    },
    toggleLastRadarData() {
      this.showLastRadarData = !this.showLastRadarData;
    }
  }
};
</script>
<style scoped lang="scss">
.GroupDimension-wrap {
  color: #ffffff;
}
.myTitle-wrap {
  padding-top: 50px;
  padding-bottom: 32px;
}
.myTitle {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
.content {
  text-align: center;
  line-height: 21px;
  margin-bottom: 32px;
  span {
    color: $Red;
  }
}
.RadarChartAndBtns {
  position: relative;
  // background-image: url('../../assets/img/radarChartBg.png');
  background-color: rgba(40, 26, 52, 1);
  height: 240px;
  width: 100%;
  // width: 320px;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  border-radius: 10px;
  padding-top: 2px;
}
#triangle-up {
  width: 0;
  height: 0;
  border-left: 93px solid transparent;
  border-right: 93px solid transparent;
  border-bottom: 173px solid rgba(40, 38, 68, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btns-wrap {
  position: absolute;
  top: 20px;
  right: 20px;
}
.btns {
  min-width: 60px;
  height: 28px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  &.noShowData {
    span {
      opacity: 0.3;
    }
  }
}
.firstDataBtn {
  background: #fb9391;
  margin-bottom: 16px;
}
.lastDataBtn {
  background: $DataPurple;
}
.RadarChart-component {
  // width: 320px;
  height: 240px;
  width: 100%;
  margin: 20px auto 0 auto;
}
.bg-checkIcon {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -167px -276px;
}
.bg-unCheckIcon {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -203px -276px;
}
</style>
<style lang="scss"></style>
