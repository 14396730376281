<template>
  <!-- <div class="SoundsIcon-wrap"> -->
  <div class="audio-icon is-active">
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
    <div class="audio-icon-nib"></div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'SoundsIcon',
  components: {},
  props: [],
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.SoundsIcon-wrap {
}
$iconWidth: 28px;
.audio-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $iconWidth;
  height: $iconWidth;
  display: flex;
  align-items: center;
  background-color: #c0a279;
  border-radius: $iconWidth;
  .audio-icon-nib {
    width: 1px;
    background: #fff;
    margin-right: 1px;
    border-radius: 1px;
    // &:nth-child(even) {
    //   height: 100%;
    // }
    // &:nth-child(odd) {
    //   height: 40%;
    // }
    // &:nth-child(3n+1) {
    //   height: 20%;
    // }
    // &:nth-child(3n-1) {
    //   height: 60%;
    // }
    // &:nth-child(3n) {
    //   height: 40%;
    // }
    // &:nth-child(4) {
    //   height: 100%;
    // }
    &:nth-child(n) {
      height: 35%;
    }
    &:nth-child(1) {
      height: 30%;
    }
    &:nth-child(8) {
      height: 30%;
    }
  }
}
$animationSpeed: 0.4s;
.audio-icon.is-active {
  .audio-icon-nib {
    // &:nth-child(even) {
    //   animation: $animationSpeed ease-in-out .15s infinite alternate pulseDownUp;
    // }
    // &:nth-child(odd) {
    //   animation: $animationSpeed ease-in-out .25s infinite alternate pulseUpDown;
    // }
    // &:nth-child(3) {
    //   animation: $animationSpeed ease-in-out .15s infinite alternate pulseUpDownFar;
    // }
    &:nth-child(4n + 1) {
      animation: $animationSpeed ease-in-out 0.55s infinite alternate pulseUpDown;
    }
    &:nth-child(4n + 2) {
      animation: $animationSpeed ease-in-out 0.85s infinite alternate pulseUpDown;
    }
    &:nth-child(4n + 3) {
      animation: $animationSpeed ease-in-out 0.15s infinite alternate pulseUpDown;
    }
    &:nth-child(4n + 4) {
      animation: $animationSpeed ease-in-out 0.35s infinite alternate pulseUpDown;
    }
  }
}
@keyframes pulseUpDown {
  from {
    transform: scaleY(0.8);
  }
  to {
    transform: scaleY(1.5);
  }
}
@keyframes pulseUpDownFar {
  from {
    transform: scaleY(1.8);
  }
  to {
    transform: scaleY(2.8);
  }
}
@keyframes pulseDownUp {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0.8);
  }
}
</style>
<style lang="scss"></style>
