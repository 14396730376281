<template>
  <div class="WebLinkCarousel-wrap">
    <!-- 網站連結輪播 -->
    <q-carousel v-model="slide" transition-prev="scale" transition-next="scale" swipeable animated control-color="" navigation padding infinite height="228px" class="text-white">
      <template v-slot:navigation-icon="{ active, onClick }">
        <q-btn v-if="active" class="activeBtn" color="Purple" flat round dense @click="onClick" />
        <q-btn v-else class="circleBtn" flat round dense @click="onClick" />
      </template>
      <q-carousel-slide v-for="item in slideData" :key="item.textOne" :name="item.textOne" class="slideItem column no-wrap flex-center">
        <!-- 鼓勵話語 -->
        <div class="content-wrap">
          <span>{{ item.contentFirst }}</span>
          <span class="textRed">{{ item.textOne }}</span>
          <span class="textRed">{{ item.textOne }}</span>
          {{ item.contentLast ? '，' : '' }}
          <div>{{ item.contentLast }}</div>
        </div>
        <button class="showDairyBtn row items-center justify-center">
          <span>{{ item.btnText }}</span>
          <span :class="[item.icon === 'share' ? 'bg-share' : 'bg-link','q-ml-sm']"></span>
        </button>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
export default {
  name: 'WebLinkCarousel',
  components: {},
  props: [],
  data() {
    return {
      // 輪播圖為哪一張
      slide: '',
      // 輪播圖資料
      slideData: [
        {
          contentFirst: '你擁有極突出的',
          textOne: '心境轉換',
          textTwo: '正念力',
          contentLast: '',
          btnText: '分享給好友並幫助他',
          icon: 'share'
        },
        {
          contentFirst: '想提升',
          textOne: '覺察入微',
          textTwo: '覺察力',
          contentLast: '你可以試試...',
          btnText: '（導購）',
          icon: 'link'
        },
        {
          contentFirst: '想提升',
          textOne: '超越自我',
          textTwo: '突破力',
          contentLast: '你可以試試...',
          btnText: '（導購）',
          icon: 'link'
        }
      ]
    };
  },
  created() {
    // 輪播圖第一張預設為 slideData 中的第一張
    this.slide = this.slideData[0].textOne;
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.WebLinkCarousel-wrap {
}
.activeBtn {
  content: ' ';
  width: 32px;
  height: 10px;
  border-radius: 50px;
  opacity: 1;
  background: $Purple;
}
.circleBtn {
  content: ' ';
  width: 10px;
  height: 10px;
  border-radius: 10px;
  opacity: 1;
  background: $LightPurple;
}
.content-wrap {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}
.slideItem {
  text-align: center;
}
.showDairyBtn {
  width: 200px;
  height: 40px;
  background: $ButtonPurple;
  border-radius: 50px;
  color: #ffffff;
}
.bg-share {
  width: 20px;
  height: 20px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -290px -197px;
}
.bg-link {
  width: 20px;
  height: 20px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -250px -197px;
}
</style>
<style lang="scss">
.WebLinkCarousel-wrap {
  .q-carousel {
    background: none;
  }
}
</style>
