<template>
  <div>
    <canvas :ref="chartId"></canvas>
  </div>
</template>
<script>
// import Chart from '../../../node_modules/chart.js/dist/chart';
// import { Bar } from 'vue-chartjs';
// export default {
//   extends: Bar,
//   props: {
//     chartdata: {
//       type: Object,
//       default: null
//     },
//     options: {
//       type: Object,
//       default: null
//     }
//   },
//   mounted() {
//     // 圖表字體顏色
//     Chart.defaults.color = '#ffffff';
//     Chart.defaults.font.family = 'Calibri';
//     // 圖表資料按紐是否出現
//     Chart.defaults.plugins.legend.display = false;
//     this.renderChart(this.chartdata, this.options);
//   },
//   watch: {
//     chartdata: function() {
//       this.renderChart(this.chartdata, this.options);
//     }
//   }
// };
import Chart from '../../../node_modules/chart.js/dist/chart';
export default {
  props: {
    chartId: {
      type: String,
      default: null
    },
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chartType: 'bar',
      chart: null
    };
  },
  mounted() {
    // 圖表字體顏色
    Chart.defaults.color = '#ffffff';
    Chart.defaults.font.family = 'Calibri';
    // 圖表資料按紐是否出現
    Chart.defaults.plugins.legend.display = false;
    this.chartConstructor(this.chartType, this.chartdata, this.options);
  },
  watch: {
    chartdata: function() {
      this.chartConstructor(this.chartType, this.chartdata, this.options);
    }
  },
  methods: {
    chartConstructor(chartType, chartData, options) {
      if (this.chart) {
        this.chart.destroy();
      }
      const chartElement = this.$refs[this.chartId];
      this.chart = new Chart(chartElement, {
        type: chartType,
        data: chartData,
        options: options
      });
    }
  }
};
</script>
