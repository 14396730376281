<template>
  <div class="BranchPoint-wrap">
    <!-- 標題線 -->
    <!-- v-touch-swipe.mouse="handleSwipe" -->
    <div class="myTitle-wrap row items-center justify-between">
      <hr class="titleLine" />
      <!-- 標題 -->
      <div class="myTitle">分支落點分析</div>
      <!-- 標題線 -->
      <hr class="titleLine" />
    </div>
    <!-- 圖表背景與圖表 -->
    <div class="chart-wrap">
      <!-- 圖表背景 -->
      <div class="chartBg"></div>
      <!-- 圖表 -->
      <BarChart class="BarChart-component" :chartId="'BranchPointBarChart'" :chartdata="chartdata" :options="options" />
    </div>
    <!-- 圖表說明 -->
    <div class="dataExplanation-wrap">
      <div class="dataExplanation-item row items-center justify-center" v-for="item in dataExplanation" :key="item.title">
        <span :class="[item.title === '等待時機' ? 'bg-perfume1' : item.title === '調頻升維' ? 'bg-perfume2' : 'bg-perfume3', 'q-mr-sm']"></span>
        <!-- 圖表說明-標題 -->
        <span class="dataExplanationTitle">{{ item.title }}</span>
        <!-- 圖表說明-說明 -->
        <span class="dataExplanationContent overflow-ellipsis">{{ item.explanation }}</span>
      </div>
    </div>
    <!-- 分隔線 -->
    <!-- <hr class="separateLine" /> -->
    <!-- 回應區塊 -->
    <div class="response-wrap row items-center justify-between">
      <div class="column">
        <!-- 鼓勵話語前言 -->
        <div class="responseSubtitle">你共觸發了</div>
        <!-- 特別回應的 %  -->
        <div class="responseTitle">
          欣頻老師特別回應
          <span class="number">{{ 52 }}%</span>
        </div>
      </div>
      <div class="bg-yellow01"></div>
    </div>

    <!-- 特別回應的內容 -->
    <!-- <div class="responeseContentBg ">
      <div class="responeseContent bg-Group_43">「話語話語話語話語話語話語」</div>
    </div> -->
    <div class="responeseContent1 overflow-ellipsis">「話語話語話語話語話語話語」</div>
  </div>
</template>

<script>
import BarChart from '../Chart/BarChart';
// import router from '../../router/index';
import tool from '../../tool';
export default {
  name: 'BranchPoint',
  components: { BarChart },
  props: [],
  data() {
    return {
      dataExplanation: [
        {
          title: '等待時機',
          explanation: '說明說明說明說明說明說明說明說明說'
        },
        {
          title: '調頻升維',
          explanation: '說明說明說明說明說明說明說明說明說'
        },
        {
          title: '欣頻老師',
          explanation: '說明說明說明說明說明說明說明說明說'
        }
      ],
      // 圖表資料
      stackedBarData: [
        {
          label: '等待時機',
          data: [50, 60, 70],
          backgroundColor: '#665AB1',
          borderColor: '#665AB1',
          barThickness: 20,
          borderWidth: 2,
          borderRadius: 12
        },
        {
          label: '調頻升維',
          data: [30, 80, 70],
          backgroundColor: '#FF928D',
          borderColor: '#FF928D',
          barThickness: 20,
          borderWidth: 2,
          borderRadius: 12
        },
        {
          label: '欣頻老師',
          data: [20, 30, 60],
          backgroundColor: '#FFED4D',
          borderColor: '#FFED4D',
          barThickness: 20,
          borderWidth: 2,
          borderRadius: 12
        }
      ]
    };
  },
  created() {},
  computed: {
    // 圖表 data
    chartdata() {
      return {
        labels: [1, 2, 3],
        datasets: this.stackedBarData
      };
    },
    // 圖表設定 options
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        scales: {
          x: {
            stacked: true, //堆積的參數
            gridLines: {
              display: false
            },
            ticks: {
              fontColor: '#fff'
            }
          },
          y: {
            ticks: {
              min: 0,
              fontColor: '#fff'
            },
            stacked: true, //堆積的參數
            gridLines: {
              borderDash: [2, 3],
              zeroLineBorderDash: [2, 3],
              zeroLineColor: 'rgba(0, 0, 0, 0.1)'
            }
          }
        }
      };
    }
  },
  methods: {
    // 偵測使用者向哪裡滑的偵測器
    // handleSwipe({ evt, ...info }) {
    //   if (process.env.NODE_ENV === 'development') console.log(info);
    //   // native Javascript event
    //   if (process.env.NODE_ENV === 'development') console.log(evt);
    //   if (info.direction === 'down') {
    //     router.push('/');
    //   }
    // },
    touchmove() {
      tool.touchmove();
    }
  }
};
</script>
<style scoped lang="scss">
.BranchPoint-wrap {
  color: #ffffff;
}
.myTitle-wrap {
  padding-top: 50px;
  padding-bottom: 23px;
}
.myTitle {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
.chartLabels-wrap {
  padding-bottom: 32px;
}
.chart-wrap {
  position: relative;
  height: 200px;
  .chartBg {
    position: absolute;
    background: #f3eaff;
    border-radius: 10px;
    right: 0;
    height: calc(100% - 29px);
    width: calc(100% - 33px);
    z-index: 1;
  }
  .BarChart-component {
    width: 100%;
    height: 200px;
    // margin: 0 auto;
    position: absolute;
    z-index: 2;
  }
}

.dataExplanation-wrap {
  padding-top: 40px;
}
.dataExplanation-item {
  margin-bottom: 12px;
  .dataExplanationIcon {
    margin-right: 8px;
  }
  .dataExplanationTitle {
    font-weight: 500;
    font-size: 16px;
    color: $Golden;
    margin-right: 9px;
  }
  .dataExplanationContent {
    font-size: 12px;
    width: calc(100% - 35px - 8px - 9px - 64px);
  }
}

.bg-img_perfume {
  width: 18px;
  height: 24px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -10px -98px;
}

.bg-img_perfume__1_ {
  width: 24px;
  height: 25px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -95px -10px;
}

.bg-img_perfume__2_ {
  width: 18px;
  height: 25px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -139px -10px;
}
// 紫色
.bg-perfume1 {
  width: 23px;
  height: 25px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -162px -197px;
}
// 紅色
.bg-perfume2 {
  width: 28px;
  height: 27px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -114px -197px;
}
// 黃色
.bg-perfume3 {
  width: 25px;
  height: 21px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -205px -197px;
}
// 黃色可愛的生物
// .bg-cuteYellowItem {
//   width: 84px;
//   height: 59px;
//   background: url('../../assets/img/css_sprites_ReportPage.png') -10px -197px;
// }
.response-wrap {
  margin: 54px auto 28px auto;
}
.responseSubtitle {
  // text-align: center;
  // padding: 40px 0 18px 0;
  margin-bottom: 10px;
}
.responseTitle {
  font-weight: 500;
  font-size: 18px;
  align-items: center;
  margin-right: 8px;
  .number {
    font-weight: 500;
    font-size: 28px;
    color: $Red;
  }
}
.responeseContentBg {
  margin-top: 28px;
  height: 140px;
  width: 100%;
  background: rgba(77, 64, 130, 0.3);
  position: relative;
  .responeseContent {
    padding: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    color: $Golden;
    background: rgba(77, 64, 130, 0.3);
    height: 120px;
    width: calc(100% - 20px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.responeseContent1 {
  background-image: url('../../assets/img/Group 43.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  // height: 240px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: $Golden;
  line-height: 167px;
}
// 話語背景
.bg-Group_43 {
  width: 345px;
  height: 167px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -10px -10px;
}
// 黃色的人
.bg-yellow01 {
  width: 88px;
  height: 63px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -131px -233px;
}
</style>
<style lang="scss"></style>
