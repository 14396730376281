<template>
  <div class="ReportPage-wrap" v-touch-pan.mouse="handlePan">
    <BranchPoint class="BranchPoint-compoment" />
    <PersonalDimension class="PersonalDimension-compoment" />
    <GroupDimension class="GroupDimension-compoment" />
    <vue-dictaphone />
  </div>
</template>

<script>
import {} from 'vuex';
import tool from '../../tool';
import BranchPoint from '../../components/ReportPage/BranchPoint';
import PersonalDimension from '../../components/ReportPage/PersonalDimension';
import GroupDimension from '../../components/ReportPage/GroupDimension';
import router from '../../router/index';
import { debounce } from 'quasar';
export default {
  name: 'ReportPage',
  components: { BranchPoint, PersonalDimension, GroupDimension },
  data() {
    return {};
  },
  computed: {},
  created() {
    tool.scrollToTop();
  },
  mounted() {},
  watch: {},
  methods: {
    // 偵測使用者向哪裡滑的偵測器
    handlePan: debounce(function({ evt, ...info }) {
      if (process.env.NODE_ENV === 'development') {
        // console.log(evt);
        console.log('offsetTop', evt.srcElement.offsetTop);
      }
      // if (process.env.NODE_ENV === 'development') console.log(info);
      if (info.direction === 'down' && evt.srcElement.offsetTop === 0) {
        router.push('/');
      }
    }, 100)
  }
};
</script>
<style scoped lang="scss">
@mixin mini_scroll {
  &::-webkit-scrollbar {
    // position: relative;
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $Primary;
    border-radius: 7px;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $Primary;
  }
}
.ReportPage-wrap {
  // background: linear-gradient(180deg, #3c2657 0%, #30234e 33.85%, #202042 69.27%, #131d39 100%);
  background-image: url('../../assets/img/bg-chart.png');
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include mini_scroll;
}
.BranchPoint-compoment {
  padding-bottom: 30px;
  width: 85.3%;
  margin: 0 auto;
}
.PersonalDimension-compoment {
  padding-bottom: 37px;
  width: 85.3%;
  margin: 0 auto;
}
.GroupDimension-compoment {
  padding-bottom: 79px;
  width: 85.3%;
  margin: 0 auto;
}
// .bg-btn_play_off {
//   width: 65px;
//   height: 68px;
//   background: url('../../assets/img/css_sprites_ReportPage.png') -10px -276px;
// }
.bg-arrow {
  width: 14px;
  height: 21px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -239px -268px;
}
.bg-BEFORE {
  width: 40px;
  height: 15px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -239px -233px;
}
.bg-BEFORE_1 {
  width: 150px;
  height: 85px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -155px -10px;
}
.bg-BEFORE_2 {
  width: 150px;
  height: 85px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -155px -115px;
}
.bg-img_flower3 {
  width: 20px;
  height: 14px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -273px -268px;
}
.bg-NOW {
  width: 48px;
  height: 18px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -325px -158px;
}
.bg-BEFORE_3 {
  width: 60px;
  height: 53px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -325px -85px;
}
.bg-Crown {
  width: 80px;
  height: 55px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -325px -10px;
}
.bg-img_flower1 {
  width: 23px;
  height: 22px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -375px -246px;
}
.bg-orangeItem {
  width: 125px;
  height: 203px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -10px -10px;
}
</style>
<style></style>
