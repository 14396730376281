<template>
  <div class="SquareItem-wrap column items-center justify-between">
    <div class="type">{{ data.type }}</div>
    <div class="title row items-center">
      {{ data.title }}
    </div>
    <div :class="data.title === '覺察力' ? 'bg-img_look' : data.title === '正念力' ? 'bg-img_heart' : 'bg-img_chart'"></div>
    <div :class="[data.change > 0 ? 'change' : 'noChange', data.change > 30 ? 'higherChange' : '']">
      {{ data.change > 0 ? `+` : '' }}
      <span>{{ data.change > 0 ? data.change : '+0' }}</span>
      <span v-if="data.change > 0" class="unit">%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SquareItem',
  components: {},
  props: ['data'],
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
.SquareItem-wrap {
  width: 30%;
  // background: #343060;
  background-image: url('../../assets/img/Group 42.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  height: 159px;
  padding: 14px 0;
}
.type {
  font-size: 12px;
  color: #ffffff;
}
.title {
  font-weight: 500;
  font-size: 18px;
  color: $Golden;
}
.bg-question {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -95px -276px;
}
.change {
  font-size: 16px;
  // height: 22px;
  // line-height: 22px;
  // margin-top: 11px;
  color: #b0a4fb;
  height: 39px;
  span {
    font-weight: bold;
    font-size: 32px;
  }
  &.higherChange {
    color: #fdff86;
  }
  .unit {
    font-size: 16px;
  }
}
.noChange {
  font-weight: 500;
  font-size: 16px;
  height: 39px;
  line-height: 22px;
  margin-top: 11px;
  color: #635f9a;
}
// 放大鏡
.bg-img_look {
  width: 30px;
  height: 30px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -375px -196px;
}
// 圖表上升
.bg-img_chart {
  width: 30px;
  height: 30px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -325px -246px;
}
// 愛心
.bg-img_heart {
  width: 30px;
  height: 30px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -325px -196px;
}
</style>
<style lang="scss"></style>
