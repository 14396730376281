<template>
  <div class="PersonalDimension-wrap">
    <div class="myTitle-wrap row items-center justify-between">
      <!-- 標題線 -->
      <hr class="titleLine" />
      <!-- 標題 -->
      <div class="myTitle">個人向度分析</div>
      <!-- 標題線 -->
      <hr class="titleLine" />
    </div>
    <!-- 三個向度分析變化數值 -->
    <div class="row items-center justify-between">
      <SquareItem v-for="item in SquareItemData" :key="item.type" :data="item" />
    </div>
    <div class="RadarChartAndBtns">
      <!-- 紫色飛毯圖片 -->
      <div class="bg-img_journey"></div>
      <img class="lookIcon" src="../../assets/img/img-look.png" alt="img-look" />
      <img class="heartIcon" src="../../assets/img/img-heart.png" alt="img-heart" />
      <img class="chartIcon" src="../../assets/img/img-chart.png" alt="img-chart" />
      <!-- 三角形背景 -->
      <div id="triangle-up"></div>
      <!-- 圖表 -->
      <RadarChart class="RadarChart-component" :chartId="'PersonalDimensionRadarChart'" :chartdata="chartdata" :options="options" />
      <!-- 前期後期按鈕 -->
      <div class="btns-wrap">
        <button :class="[showFirstRadarData ? '' : 'noShowData', 'btns firstDataBtn row items-center justify-center']" @click="toggleFirstRadarData">
          <span :class="[showFirstRadarData ? 'bg-checkIcon' : 'bg-unCheckIcon']"></span>
          <span>前期</span>
        </button>
        <button :class="[showLastRadarData ? '' : 'noShowData', 'btns lastDataBtn row items-center justify-center']" @click="toggleLastRadarData">
          <span :class="[showLastRadarData ? 'bg-checkIcon' : 'bg-unCheckIcon']"></span>
          <span>後期</span>
        </button>
      </div>
    </div>
    <!-- 日誌內容 -->
    <transition name="slideDown">
      <div v-if="showDairy" class="DairyItem-component">
        <DairyItem v-for="item in DairyItemData" :key="item.type" :data="item" />
      </div>
    </transition>
    <!-- 展開日誌的按鈕 -->
    <button class="showDairyBtn row items-center justify-center" @click="showDairy = !showDairy">
      <span class="btnText">{{ showDairy ? '收起' : '展開' }}話語日誌</span>
      <span :class="[showDairy ? 'arrowUp' : '', 'bg-arrow_up_icon']"></span>
    </button>
  </div>
</template>

<script>
import RadarChart from '../Chart/RadarChart';
import SquareItem from '../ReportPage/SquareItem';
import DairyItem from '../ReportPage/DairyItem';
import { mapState } from 'vuex';
export default {
  name: 'PersonalDimension',
  components: { RadarChart, SquareItem, DairyItem },
  props: [],
  data() {
    return {
      // 顯示前期資料
      showFirstRadarData: true,
      // 顯示後期資料
      showLastRadarData: true,
      // 是否顯示日誌
      showDairy: false
    };
  },
  created() {},
  computed: {
    ...mapState('ReportPageModule', ['personalData']),
    // 三個向度分析變化數值的方框中的資料 item.score
    SquareItemData() {
      return this.personalData.map(item => {
        return {
          type: item.title,
          title: item.text,
          change: item.score
        };
      });
    },
    // 圖表資料
    radarData() {
      let result = [];
      // 如果要顯示前期資料，才將資料加入
      if (this.showFirstRadarData) {
        const data = {
          label: '前期',
          // data: [100, 100, 100],
          data: [this.personalData[0]?.range[0] || 0, this.personalData[1]?.range[0] || 0, this.personalData[2]?.range[0] || 0],
          backgroundColor: 'rgba(251, 147, 145, 0.6)',
          borderColor: 'rgba(251, 147, 145, 0.6)',
          pointRadius: 0
        };
        if (result.length === 0) {
          result.push(data);
        } else {
          result.unshift(data);
        }
      }
      // 如果要顯示後期資料，才將資料加入
      if (this.showLastRadarData) {
        const data = {
          label: '後期',
          // data: [100, 100, 100],
          data: [this.personalData[0]?.range[1] || 0, this.personalData[1]?.range[1] || 0, this.personalData[1]?.range[1] || 0],
          backgroundColor: 'rgba(102, 90, 177, 0.6)',
          borderColor: 'rgba(102, 90, 177, 0.6)',
          pointRadius: 0
        };
        result.push(data);
      }
      return result;
    },
    // 圖表 data
    chartdata() {
      return {
        labels: [this.personalData[0]?.text || '', this.personalData[1]?.text || '', this.personalData[2]?.text || ''],
        datasets: this.radarData
      };
    },
    // 圖表設定 options
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 1,
            ticks: {
              showLabelBackdrop: false
            },
            angleLines: {
              color: 'rgba(250, 250, 250, 1)'
            },
            pointLabels: {
              color: 'rgba(250, 250, 250, 1)'
            }
          }
        }
      };
    },
    // 日誌資料
    DairyItemData() {
      return this.personalData.map(item => {
        return {
          type: item.title,
          description: item.description,
          data: [
            {
              title: '前期',
              content: item.summaries[0].join(''),
              audio: item.audio[0]
            },
            {
              title: '後期',
              content: item.summaries[1].join(''),
              audio: item.audio[1]
            }
          ]
        };
      });
    }
  },
  methods: {
    toggleFirstRadarData() {
      this.showFirstRadarData = !this.showFirstRadarData;
    },
    toggleLastRadarData() {
      this.showLastRadarData = !this.showLastRadarData;
    }
  }
};
</script>
<style scoped lang="scss">
.PersonalDimension-wrap {
  color: #ffffff;
}
.myTitle-wrap {
  padding-top: 50px;
  padding-bottom: 30px;
}
.myTitle {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}
.RadarChart-component {
  // width: 320px;
  width: 100%;
  height: 240px;
  margin: 20px auto 0 auto;
}
.bg-arrow_up_icon {
  width: 10px;
  height: 12px;
  background: url('../../assets/img/css_sprites_MainPage.png') -232px -110px;
  transform: rotate(180deg);
  animation: upAndDown 0.8s infinite;
  &.arrowUp {
    transform: none;
    animation: none;
  }
}
.showDairyBtn {
  width: 200px;
  height: 40px;
  background: $ButtonPurple;
  border-radius: 50px;
  color: #ffffff;
  margin: 40px auto -10px auto;
  .btnText {
    margin-right: 6.2px;
  }
}
.DairyItem-component {
}
.RadarChartAndBtns {
  position: relative;
  // background-image: url('../../assets/img/radarChartBg.png');
  background-color: rgba(40, 26, 52, 1);
  height: 240px;
  width: 100%;
  // width: 320px;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  border-radius: 10px;
  padding-top: 2px;
  margin-top: 20px;
}
#triangle-up {
  width: 0;
  height: 0;
  border-left: 93px solid transparent;
  border-right: 93px solid transparent;
  border-bottom: 173px solid rgba(40, 38, 68, 0.6);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lookIcon {
  position: absolute;
}
.heartIcon {
  position: absolute;
}
.chartIcon {
  position: absolute;
}
.btns-wrap {
  position: absolute;
  top: 20px;
  right: 20px;
}
.btns {
  min-width: 60px;
  height: 28px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  &.noShowData {
    span {
      opacity: 0.3;
    }
  }
}
.firstDataBtn {
  background: #fb9391;
  margin-bottom: 16px;
}
.lastDataBtn {
  background: $DataPurple;
}
// 眼睛(開)
.bg-eye {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -330px -197px;
  margin-right: 2px;
}
// 眼睛(關)
.bg-eye_off {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -131px -276px;
  margin-right: 2px;
}
// 打勾
.bg-checkIcon {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -167px -276px;
}
// 沒有打勾
.bg-unCheckIcon {
  width: 16px;
  height: 16px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -203px -276px;
}
// 紫色飛毯
.bg-img_journey {
  width: 101px;
  height: 65px;
  background: url('../../assets/img/css_sprites_ReportPage1.png') -10px -233px;
  position: absolute;
  top: -21px;
  left: -23px;
}
</style>
<style lang="scss"></style>
