<template>
  <div class="DairyItem-wrap column items-center justify-between">
    <!-- 分類(標題) -->
    <div class="type">{{ data.type }}</div>
    <!-- 前言 -->
    <div class="content">
      {{ data.description }}
      <!-- <span>{{ '更細膩' }}</span> -->
    </div>
    <!-- 前期、後期資料 -->
    <div class="dataItem" v-for="(item, index) in data.data" :key="data.type + item.title">
      <div class="dataTitle-wrap row items-center">
        <!-- 標題(前期或後期) -->
        <span class="dataTitle">{{ item.title }}</span>
        <!-- 錄音+播放鍵 -->
        <div class="dataRecordItem-wrap row items-center justify-center">
          <!-- 錄音套件 -->
          <vue-dictaphone class="row items-center justify-center" @stop="handleRecording($event, index)" v-slot="{ startRecording, stopRecording }" mime-type="audio/mp3">
            <!-- 開始錄音 -->
            <span
              v-if="recording !== index"
              class="dataRecordItem row items-center justify-center"
              @click="
                record(index, stopRecording);
                startRecording();
              "
            >
              <span class="bg-btn_mic"></span>
              <span>錄製</span>
            </span>
            <!-- 正在錄製中 -->
            <span v-else class="dataRecordItem recordingText-wrap row items-center">
              <span class="recordingText">錄製中...</span>
            </span>
            <!-- 正在錄製中出現的 icon -->
            <!-- <img
            v-if="recording === index"
            src="../../assets/img/btn-playing.png"
            alt="btn-playing"
            class="playIcon"
            @click="
              stopRecording();
              myStopRecording();
            "
          /> -->
            <!-- <span
            class="dataRecordItem row items-center justify-center"
            v-if="recording === index"
            @click="
              stopRecording();
              myStopRecording();
            "
          >
            <span>結束錄音</span>
          </span> -->
            <!-- 結束錄音 icon -->
            <q-icon
              v-if="recording === index"
              class="stopIcon"
              @click="
                stopRecording();
                myStopRecording();
              "
              name="stop"
            />
          </vue-dictaphone>
          <!-- 沒有音檔的 icon -->
          <img v-if="!audioSrc[index] && recording !== index" class="playIcon" src="../../assets/img/btn-play-off.png" alt="btn-play-off" />
          <!-- 播放音檔按鈕(有音檔) -->
          <img
            v-else-if="(audioSrc[index] && playing !== index && recording !== index) || (stopPlaying && recording !== index)"
            class="playIcon"
            src="../../assets/img/btn-play.png"
            alt="btn-play"
            @click="playMedia(index)"
          />
          <!-- 音檔播放的 icon(聲音動畫) -->
          <!-- <img v-else-if="audioSrc[index] && playing === index" src="../../assets/img/btn-playing.png" alt="btn-playing" class="playIcon" /> -->
          <SoundsIcon v-else-if="(audioSrc[index] && playing === index) || (!stopPlaying && recording !== index)" />
          <!-- 暫停音檔按鈕 -->
          <q-icon v-if="playing === index && !stopPlaying" class="stopIcon q-ml-sm" @click="pauseMedia(index)" name="stop" />
        </div>
      </div>
      <div class="dataContent">{{ item.content }}</div>
    </div>
    <!-- 錄音檔(不顯示) -->
    <audio v-show="false" ref="myAudio" controls :src="tempAudioScr"></audio>
    <!-- 分隔線 -->
    <hr class="separateLine" />
  </div>
</template>

<script>
import SoundsIcon from './SoundsIcon.vue';
export default {
  name: 'DairyItem',
  components: { SoundsIcon },
  props: ['data'],
  data() {
    return {
      // 正在播放的 index
      playing: null,
      // 是否有正在暫停音檔
      stopPlaying: false,
      // 正在錄音的 index
      recording: null,
      // 錄音檔(前期+後期)
      audioSrc: [],
      // 正在播放的音檔
      tempAudioScr: null
    };
  },
  created() {
    if (!this.data.data) return;
    this.audioSrc[0] = this.data.data[0].audio?.src || null;
    this.audioSrc[1] = this.data.data[1].audio?.src || null;
  },
  mounted() {
    const myAudio = this.$refs.myAudio;
    const self = this;
    // 監聽音檔結束
    myAudio.addEventListener(
      'ended',
      function() {
        self.playing = null;
      },
      false
    );
  },
  computed: {},
  watch: {
    data: function() {
      this.audioSrc[0] = this.data.data[0].audio?.src || null;
      this.audioSrc[1] = this.data.data[1].audio?.src || null;
    }
  },
  methods: {
    // 錄音
    record(index, stopRecording) {
      // 如果有其他地方在錄音，先把錄音暫停再重新開始
      if (this.recording && this.recording !== index) {
        console.log(1);
        stopRecording;
      }
      this.recording = index;
    },
    // 停止錄音
    myStopRecording() {
      this.recording = null;
    },
    // 播放音檔
    playMedia(index) {
      const myAudio = this.$refs.myAudio;
      // 如果有其他音檔正在播放，先關掉其他音檔，再播放新的
      if (this.playing && this.playing !== index) {
        myAudio.pause();
      }
      this.playing = index;
      this.tempAudioScr = this.audioSrc[index];
      this.$nextTick(() => {
        myAudio.play();
      });
      // 如果是先暫停播放音檔，再按下開始
      if (this.stopPlaying) {
        this.stopPlaying = false;
      }
    },
    // 暫停音檔
    pauseMedia() {
      this.stopPlaying = true;
      const myAudio = this.$refs.myAudio;
      myAudio.pause();
    },
    // 處理錄音
    handleRecording({ blob, src }, index) {
      let newAudioData = [...this.audioSrc];
      newAudioData[index] = src;
      this.audioSrc = newAudioData;
      if (process.env.NODE_ENV === 'development') console.log(blob);
    }
  },
  beforeDestroy() {
    const myAudio = this.$refs.myAudio;
    myAudio.removeEventListener(
      'ended',
      function() {
        self.playing = null;
      },
      false
    );
  }
};
</script>
<style scoped lang="scss">
.DairyItem-wrap {
  color: #ffffff;
}
.type {
  font-size: 12px;
  padding: 40px 0 20px 0;
}
.content {
  font-size: 16px;
  padding-bottom: 21px;
  span {
    color: $Red;
    font-weight: 500;
  }
}
.dataItem {
  padding: 7px 0 40px 0;
  width: 100%;
  .dataTitle-wrap {
    padding-bottom: 16px;
    .dataTitle {
      color: #c0a279;
      border-left: 4px solid #c0a279;
      padding: 0 8px;
    }
    .dataRecordItem-wrap {
      margin-left: auto;
    }
    .dataRecordItem {
      margin-right: 8px;
      width: 64px;
      height: 28px;
      background: #91b5f8;
      border-radius: 50px;
      color: #1c0e74;
      font-size: 12px;
      .micIcon {
        margin-right: 4.9px;
      }
    }
    .stopIcon {
      background-color: #c0a279;
      height: 28px;
      width: 28px;
      line-height: 28px;
      border-radius: 28px;
      font-size: 19px;
    }
    .recordingText-wrap {
      padding: 0 9px;
      .recordingText {
        overflow: hidden;
        width: 46px;
        white-space: nowrap;
        animation: recordingDots 2s infinite;
      }
    }
  }
  .playIcon {
    margin: -15px;
    height: 57px;
  }
  .dataContent {
    width: 100%;
    min-height: 32px;
    background: #281a34;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
  }
}
// 錄製中的點點點的動畫
@keyframes recordingDots {
  0% {
    width: 46px;
  }
  25% {
    width: 36px;
  }
  50% {
    width: 39px;
  }
  75% {
    width: 42px;
  }
  100% {
    width: 46px;
  }
}
// 麥克風 icon
.bg-btn_mic {
  width: 14px;
  height: 14px;
  background: url('../../assets/img/css_sprites_ReportPage.png') -239px -276px;
}
</style>
<style lang="scss"></style>
